<template>
	<div class="cbox">
		<div class="headbar">
			<div style="display: flex;">
				<span>
					关键字：
				</span>
				<el-input v-model="keyword" placeholder="标题关键字" style="max-width: 200px; margin-right: 10px;" />
				<span>
					专业：
				</span>
				<el-select v-model="zhuanye" style="max-width: 200px; margin-right: 10px;" clearable>
					<el-option label="美术学" value="美术学"></el-option>
					<el-option label="体育" value="体育"></el-option>
					<el-option label="学前教育" value="学前教育"></el-option>
					<el-option label="音乐学" value="音乐学"></el-option>
					<el-option label="其他" value="其他"></el-option>

				</el-select>
				<el-button type="primary" plain size="small" icon="el-icon-search" @click="onSearch">搜索</el-button>

				<!-- <el-button type="primary" size="small" icon="el-icon-plus" @click="add">添加分数</el-button> -->


				<el-button style="margin-left: 10px;" type="text" icon="el-icon-download">
					<a href="/xls/分数导入模板.xls" style="text-decoration: none;color:inherit">下载分数导入模板</a>
				</el-button>

				<el-button id="btnimport1" style="margin-left: 10px; " @click="dialogVisible=true"
					icon="el-icon-upload">导入分数</el-button>

				<el-button id="btnimport2" :type="isOpen==1?'success':'warning'" style="margin-left: 30px; "
					@click="setOpen" icon="el-icon-upload">{{isOpen==1?'已开放查询':'未开放查询'}}</el-button>

			</div>

		</div>
		<div class="bcontent">

			<el-table :data="DataList" stripe border>

				<el-table-column type="index" label="编号" width="80">
				</el-table-column>
				<el-table-column prop="zkzh" label="考生号" width="100">
				</el-table-column>
				<el-table-column prop="xm" label="学生姓名" width="100">
				</el-table-column>
				<el-table-column prop="zhuanye" label="专业" width="100">
				</el-table-column>

				<el-table-column :prop="k" v-for="(course,k,i) in Courses" :key="i" :label="course" min-width="100">
				</el-table-column>


				<el-table-column prop="createdAt" label="导入时间" width="160">
				</el-table-column>

				<el-table-column fixed="right" label="操作" width="100">
					<template slot-scope="scope">

						<el-button type="text" style="color: orangered;" @click="remove(scope.row.id)">删除</el-button>


					</template>
				</el-table-column>



			</el-table>
		</div>
		<div style="padding-top: 10px;display: flex; flex-direction: row-reverse;">

			<el-pagination background layout="total,prev, pager, next" :total="page.count"
				:current-page="page.current_page" :page-size="10" @current-change="paginate">
			</el-pagination>

		</div>




		<el-dialog title="导入分数" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px">
			请选择专业：
			<el-select v-model="zhuanye">
				<el-option label="美术学" value="美术学"></el-option>
				<el-option label="体育" value="体育"></el-option>
				<el-option label="学前教育" value="学前教育"></el-option>
				<el-option label="音乐学" value="音乐学"></el-option>
				<el-option label="其他" value="其他"></el-option>
			</el-select>
			<el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadTemp"
				:on-success="handleXlsUpload" :show-file-list="false" name="image">
				<el-button id="btnimport1" style="margin-left: 10px; "
					icon="el-icon-upload">导入分数（重复学生会自动覆盖更新）</el-button>
			</el-upload>


		</el-dialog>





	</div>
</template>

<script>
	let course = require("./kecheng.js")
	export default {
		components: {

		},
		data() {
			return {
				zhuanye: "",
				dialogVisible: false,
				isOpen: 0,
				isAdmin:false,
				EditItem: {
					id: 0,
					lqtype: "",
					lqyear: "",
					province: "",
					kelei: "",
					pici: "",
					zhuanye: "",
					shengkongxian: "",
					score_max: "",
					score_min: "",
					score_avg: "",
					beizhu: ""
				},
				DataList: [],
				keyword: "",
				Courses: course.course,
				page: {
					current_page: 1,
					next_page: 1,
					pre_page: 1,
					total_page: 1,
					count: 0
				},

			};
		},

		mounted() {
			if (sessionStorage.getItem("username") == "admin") {
				this.isAdmin = true
			}
			this.getList();
			this.checkOpen()

		},
		methods: {

			setOpen() {

				this.$http.post("/api/kaofen_setopen", {
					isopen: this.isOpen ? 0 : 1
				}).then(res => {
					this.checkOpen()
				})
			},
			checkOpen() {
				this.$http.post("/api/kaofen_checkopen", {}).then(res => {
					this.isOpen = res.data.isopen
				})
			},
			onSearch() {
				this.getList();
			},
			getList(page) {
				this.$http.post("/api/kaofen_list", {
					keyword: this.keyword,
					zhuanye: this.zhuanye,
					pagesize: 10,
					page: this.page.current_page
				}).then(
					res => {
						for (let item of res.data.data) {
							if (item.detailJson) {
								item.detailJson = JSON.parse(item.detailJson)
								for (let k in item.detailJson) {
									item[k] = item.detailJson[k]
								}
							}
						}
						this.DataList = res.data.data;
						this.page = res.data.page

					})
			},

			paginate(val) {
				this.page.current_page = val
				this.getList()
			},

			add() {
				this.EditItem = {
						id: 0,
						lqtype: "",
						lqyear: "",
						province: "",
						kelei: "",
						pici: "",
						zhuanye: "",
						shengkongxian: "",
						score_max: "",
						score_min: "",
						score_avg: "",
						beizhu: ""
					},
					this.dialogVisible = true
			},
			updateDetail(e) {
				this.EditItem = e
				this.dialogVisible = true
			},
			handleXlsUpload(e) {
				if (!this.zhuanye) {
					this.$message.error("请选择专业")
					return
				}
				this.$http.post("/api/import_kaofen_xls", {
					url: e.src,
					zhuanye: this.zhuanye
				}).then(res => {
					this.$message({
						type: 'success',
						message: '导入成功'
					});

					this.getList();
				})
			},

			remove(id) {
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/kaofen_delete", {
						id: id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '删除成功'
						});

						this.getList(this.page.current_page);
					})

				}).catch(() => {

				});
			},
			saveForm() {
				this.$http.post("/api/kaofen_update", this.EditItem).then(res => {
					this.$message({
						type: 'success',
						message: '保存成功'
					});
					this.dialogVisible = false

					this.getList();
				})
			}


		}
	}
</script>